/* 重置 */
body,
html,
header,
footer,
nav,
section,
aside,
article,
div,
span,
ul,
li,
a,
h1,
h2,
h3,
h4,
h5,
h6,
button,
input,
select,
textarea,
p {
  padding: 0;
  margin: 0;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "\5FAE\8F6F\96C5\9ED1", Arial, sans-serif;
  font-weight: normal;
}
ul,
li {
  list-style: none;
}
a {
  text-decoration: none;
}
button,
input {
  outline: none;
  -webkit-appearance: none;
}
.clearfix::after {
  content: "";
  height: 0;
  display: block;
  clear: both;
  overflow: hidden;
  visibility: hidden;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
.container {
  min-width: 1024px;
  max-width: 1200px;
  margin: 0 auto;
}
body,
html {
  width: 100%;
}
body {
/* 禁止微信页面字体变大 */
  -webkit-text-size-adjust: 100% !important;
  text-size-adjust: 100% !important;
  -moz-text-size-adjust: 100% !important;
  -webkit-tap-highlight-color: transparent;
}
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
