/* header */
.header {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 2000;
  background-color: #fff;
  box-shadow: 0 2px 10px 5px rgba(235,235,235,0.5);
  transition: 0.5s opacity;
}
.header .notification {
  width: 100%;
  line-height: 30px;
  background-color: #fef6e9;
  color: #ffa51f;
  font-size: 14px;
  text-align: center;
}
.header .header-box {
  display: flex;
  justify-content: space-between;
}
.header .header-box .logo {
  width: 200px;
  height: 80px;
  background-color: #32b16b;
  text-indent: -10000px;
  position: relative;
}
.header .header-box .logo .logo-img {
  width: 140px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.header .header-box .nav {
  flex: 1;
  display: flex;
}
.header .header-box .nav .nav-item {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 50px;
  max-width: 130px;
  font-size: 20px;
}
@media (max-width: 1100px) {
  .header .header-box .nav .nav-item {
    font-size: 18px;
  }
}
.header .header-box .nav .nav-item .nav-link {
  color: #333;
}
.header .header-box .nav .nav-item.active .nav-link {
  color: #32b16b;
  position: relative;
}
.header .header-box .nav .nav-item.active .nav-link::after {
  content: '';
  display: block;
  width: 30px;
  height: 3px;
  background-color: #32b16b;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}
.header .header-box .login-group {
  height: 80px;
  line-height: 80px;
}
.header .header-box .login-group .btn {
  display: inline-block;
  text-align: center;
  width: 120px;
  height: 36px;
  line-height: 36px;
  font-size: 16px;
  border: 1px solid #32b16b;
  border-radius: 10px;
  cursor: pointer;
  box-sizing: border-box;
}
@media (max-width: 1100px) {
  .header .header-box .login-group .btn {
    width: 80px;
  }
}
.header .header-box .login-group .login {
  color: #32b16b;
  margin-right: 10px;
}
.header .header-box .login-group .experience {
  color: #fff;
  background-color: #32b16b;
}
/* bn */
.bn {
  min-width: 1024px;
  margin-top: 80px;
  position: relative;
}
.bn #nav-bn {
  position: absolute;
  top: -80px;
}
.bn .bn-box {
  width: 100%;
}
.bn .bn-box .bn-item {
  width: 100%;
  display: flex;
  overflow: hidden;
  justify-content: center;
}
.bn .bn-box .bn-item .bn-item-img {
  width: 100%;
}
.bn .bn-pagination {
  background-color: #32b16b;
  opacity: 1;
}
/* reg */
.reg {
  display: none;
}
.reg.square {
  display: block;
  width: 250px;
  height: 220px;
  background-color: #fff;
  padding: 18px 16px;
  box-sizing: border-box;
  position: fixed;
  top: 50%;
  right: 30px;
  z-index: 2000;
  transform: translateY(-50%);
  border-radius: 5px;
  box-shadow: 0 12px 15px 0 rgba(71,71,71,0.1), 0 20px 60px 0 rgba(71,71,71,0.11);
}
.reg.square .reg-text-01,
.reg.square .reg-text-02 {
  display: block;
  text-align: center;
  font-size: 20px;
  line-height: 30px;
}
.reg.square .reg-text-01 span,
.reg.square .reg-text-02 span {
  color: #fdde00;
}
.reg.square .reg-input {
  display: block;
  width: 200px;
  height: 40px;
  font-size: 16px;
  box-sizing: border-box;
  margin: 30px auto 5px;
  border: 1px solid #979797;
  border-radius: 30px;
  padding: 0 20px;
}
.reg.square .reg-button {
  display: block;
  width: 200px;
  height: 40px;
  margin: 10px auto;
  line-height: 40px;
  font-size: 16px;
  box-sizing: border-box;
  background-color: #fdde00;
  border: 1px solid #fdde00;
  border-radius: 30px;
  color: #333;
  cursor: pointer;
}
.reg.square .to-top {
  display: none;
}
.reg.strip {
  width: 100%;
  min-width: 1024px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2000;
  background-color: #fff;
  box-shadow: 0 2px 10px 5px rgba(235,235,235,0.5);
}
.reg.strip .reg-text-01,
.reg.strip .reg-text-02 {
  font-size: 24px;
  color: #333;
}
.reg.strip .reg-text-01 span,
.reg.strip .reg-text-02 span {
  color: #32b16b;
}
.reg.strip .reg-input-box {
  position: relative;
  margin-left: 80px;
}
.reg.strip .reg-input {
  width: 350px;
  line-height: 58px;
  border-radius: 5px 0px 0px 5px;
  color: #333;
  font-size: 20px;
  padding: 0 20px;
  border: 1px solid #fff;
  box-sizing: border-box;
  box-shadow: 0px 2px 20px 0px rgba(230,230,230,0.5);
}
.reg.strip .reg-input::after {
  content: '';
  width: 0;
  height: 0;
  border: 10px solid #32b16b;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -20px;
}
.reg.strip .reg-button {
  width: 120px;
  height: 60px;
  line-height: 60px;
  font-size: 20px;
  background-color: #32b16b;
  color: #fff;
  text-align: center;
  border-radius: 0px 5px 5px 0px;
  border: none;
}
/* to-top */
.to-top {
  width: 80px;
  height: 80px;
  box-shadow: 0px 2px 20px 0px rgba(230,230,230,0.5);
  background-color: #fff;
  position: absolute;
  right: 0px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}
.to-top .to-top-icon {
  color: #32b16b;
  font-size: 40px;
}
.to-top .to-top-text {
  color: #32b16b;
  font-size: 12px;
}
.to-top.disable {
  display: none;
}
/* introduce */
.introduce {
  min-width: 1024px;
  background-color: #fbfbfb;
  position: relative;
}
.introduce #nav-introduce {
  position: absolute;
  top: -80px;
}
.introduce .introduce-top {
  display: flex;
  justify-content: space-between;
  padding: 70px 30px 90px;
}
.introduce .introduce-top .introduce-top-item {
  width: 120px;
}
.introduce .introduce-top .introduce-top-item img {
  width: 100%;
}
.introduce .introduce-top .introduce-top-item span {
  display: block;
  text-align: center;
  font-size: 20px;
  color: #666;
  margin-top: 20px;
}
.introduce .introduce-bottom {
  min-width: 1024px;
  padding-bottom: 164px;
  position: relative;
}
.introduce .introduce-bottom .introduce-swiper {
  padding-bottom: 10px;
}
.introduce .introduce-bottom .introduce-bottom-box .introduce-bottom-item {
  width: 100%;
  height: 400px;
  background: #fff;
  box-shadow: 0px 10px 2px 0px rgba(215,254,233,0.2);
  border-radius: 50px;
  display: flex;
  justify-content: center;
  padding-top: 80px;
  box-sizing: border-box;
}
.introduce .introduce-bottom .introduce-bottom-box .introduce-bottom-item .introduce-bottom-item-img {
  width: 200px;
  height: 200px;
}
.introduce .introduce-bottom .introduce-bottom-box .introduce-bottom-item .introduce-bottom-item-content {
  margin-left: 85px;
}
.introduce .introduce-bottom .introduce-bottom-box .introduce-bottom-item .introduce-bottom-item-content .introduce-bottom-item-content-title {
  font-size: 24px;
  color: #333;
  margin-bottom: 30px;
  font-weight: normal;
}
.introduce .introduce-bottom .introduce-bottom-box .introduce-bottom-item .introduce-bottom-item-content .introduce-bottom-item-content-text {
  width: 700px;
  font-size: 16px;
  line-height: 30px;
  color: #666;
}
.introduce .introduce-bottom .introduce-btn-prev,
.introduce .introduce-bottom .introduce-btn-next {
  width: 70px;
  height: 70px;
  font-size: 70px;
  cursor: pointer;
  outline: none;
  color: #32b16b;
  user-select: none;
}
.introduce .introduce-bottom .introduce-btn-prev.swiper-button-disabled {
  color: #999;
}
.introduce .introduce-bottom .introduce-btn-next.swiper-button-disabled {
  color: #999;
}
.introduce .introduce-bottom .introduce-btn-prev {
  position: absolute;
  bottom: 40px;
  left: 45%;
  transform: translateX(-50%);
}
.introduce .introduce-bottom .introduce-btn-next {
  position: absolute;
  bottom: 40px;
  left: 55%;
  transform: translateX(-50%);
}
/* advantage */
.advantage {
  position: relative;
}
.advantage #nav-advantage {
  position: absolute;
  top: -80px;
}
.advantage .advantage-animation {
  min-width: 1024px;
  position: relative;
  user-select: none;
}
.advantage .advantage-animation .advantage-animation-bg {
  width: 100%;
}
.advantage .advantage-animation .advantage-animation-icon-01 {
  width: 6%;
  position: absolute;
  top: 50%;
  left: 32%;
  animation: 3s move infinite;
}
@-moz-keyframes move {
  0% {
    left: 32%;
  }
  50% {
    left: 36%;
  }
  100% {
    left: 32%;
  }
}
@-webkit-keyframes move {
  0% {
    left: 32%;
  }
  50% {
    left: 36%;
  }
  100% {
    left: 32%;
  }
}
@-o-keyframes move {
  0% {
    left: 32%;
  }
  50% {
    left: 36%;
  }
  100% {
    left: 32%;
  }
}
@keyframes move {
  0% {
    left: 32%;
  }
  50% {
    left: 36%;
  }
  100% {
    left: 32%;
  }
}
.advantage .advantage-animation .advantage-animation-cloud-01 {
  width: 9%;
  position: absolute;
  top: 20%;
  left: 46%;
  animation: 5s fade1 infinite;
}
@-moz-keyframes fade1 {
  0% {
    opacity: 1;
    left: 46%;
  }
  50% {
    opacity: 0.5;
    left: 40%;
  }
  100% {
    opacity: 1;
    left: 46%;
  }
}
@-webkit-keyframes fade1 {
  0% {
    opacity: 1;
    left: 46%;
  }
  50% {
    opacity: 0.5;
    left: 40%;
  }
  100% {
    opacity: 1;
    left: 46%;
  }
}
@-o-keyframes fade1 {
  0% {
    opacity: 1;
    left: 46%;
  }
  50% {
    opacity: 0.5;
    left: 40%;
  }
  100% {
    opacity: 1;
    left: 46%;
  }
}
@keyframes fade1 {
  0% {
    opacity: 1;
    left: 46%;
  }
  50% {
    opacity: 0.5;
    left: 40%;
  }
  100% {
    opacity: 1;
    left: 46%;
  }
}
.advantage .advantage-animation .advantage-animation-cloud-02 {
  width: 9%;
  position: absolute;
  top: 40%;
  left: 86%;
  animation: 3s fade2 infinite;
}
@-moz-keyframes fade2 {
  0% {
    opacity: 1;
    left: 86%;
  }
  50% {
    opacity: 0.5;
    left: 80%;
  }
  100% {
    opacity: 1;
    left: 86%;
  }
}
@-webkit-keyframes fade2 {
  0% {
    opacity: 1;
    left: 86%;
  }
  50% {
    opacity: 0.5;
    left: 80%;
  }
  100% {
    opacity: 1;
    left: 86%;
  }
}
@-o-keyframes fade2 {
  0% {
    opacity: 1;
    left: 86%;
  }
  50% {
    opacity: 0.5;
    left: 80%;
  }
  100% {
    opacity: 1;
    left: 86%;
  }
}
@keyframes fade2 {
  0% {
    opacity: 1;
    left: 86%;
  }
  50% {
    opacity: 0.5;
    left: 80%;
  }
  100% {
    opacity: 1;
    left: 86%;
  }
}
.advantage .advantage-animation .advantage-animation-bubble-01,
.advantage .advantage-animation .advantage-animation-bubble-02 {
  width: 8px;
  height: 14px;
  background: radial-gradient(#fff 5%, #c4fed8 95%);
  border-radius: 50%;
  position: absolute;
  top: 79%;
  left: 28%;
  transform: rotateZ(18deg);
  opacity: 0;
}
.advantage .advantage-animation .advantage-animation-bubble-01 {
  animation: 3.6s bubble infinite;
}
.advantage .advantage-animation .advantage-animation-bubble-02 {
  animation: 3.6s bubble infinite 1.8s;
}
@-moz-keyframes bubble {
  0% {
    opacity: 0.8;
    top: 79%;
    left: 28%;
    width: 8px;
    height: 14px;
  }
  100% {
    opacity: 0.2;
    top: 55%;
    left: 30%;
    width: 18px;
    height: 20px;
  }
}
@-webkit-keyframes bubble {
  0% {
    opacity: 0.8;
    top: 79%;
    left: 28%;
    width: 8px;
    height: 14px;
  }
  100% {
    opacity: 0.2;
    top: 55%;
    left: 30%;
    width: 18px;
    height: 20px;
  }
}
@-o-keyframes bubble {
  0% {
    opacity: 0.8;
    top: 79%;
    left: 28%;
    width: 8px;
    height: 14px;
  }
  100% {
    opacity: 0.2;
    top: 55%;
    left: 30%;
    width: 18px;
    height: 20px;
  }
}
@keyframes bubble {
  0% {
    opacity: 0.8;
    top: 79%;
    left: 28%;
    width: 8px;
    height: 14px;
  }
  100% {
    opacity: 0.2;
    top: 55%;
    left: 30%;
    width: 18px;
    height: 20px;
  }
}
.advantage .advantage-content-box {
  display: flex;
  flex-wrap: wrap;
}
.advantage .advantage-content-box .advantage-content-item {
  width: 33.33%;
  display: flex;
  padding: 15px;
  box-sizing: border-box;
}
.advantage .advantage-content-box .advantage-content-item .advantage-content-item-icon {
  width: 75px;
  height: 75px;
  font-size: 75px;
  color: #32b16b;
}
.advantage .advantage-content-box .advantage-content-item .advantage-content-item-section {
  flex: 1;
  padding: 20px;
}
.advantage .advantage-content-box .advantage-content-item .advantage-content-item-section .advantage-content-item-title {
  font-size: 24px;
  line-height: 32px;
  color: #32b16b;
}
.advantage .advantage-content-box .advantage-content-item .advantage-content-item-section .advantage-content-item-text {
  font-size: 20px;
  line-height: 32px;
  color: #666;
}
.advantage .advantage-asha {
  height: 300px;
  min-width: 1024px;
  box-shadow: 0px 2px 20px 0px rgba(230,230,230,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.advantage .advantage-asha .advantage-asha-img {
  display: block;
  width: 125px;
  height: 125px;
  margin-right: 87px;
}
.advantage .advantage-asha .advantage-asha-text {
  color: #303030;
  font-size: 20px;
  line-height: 32px;
}
/* course */
.course {
  font-size: 0px /* 使得inline-box的间隙消失，所有子元素必须手动添加font-size */;
  padding: 85px 0 105px 0;
  position: relative;
}
.course #nav-course {
  position: absolute;
  top: -80px;
}
.course .course-tab-radio {
  display: none;
}
.course .course-tab {
  display: inline-block;
  width: 25%;
  height: 60px;
  line-height: 60px;
  text-align: center;
  box-sizing: border-box;
  border: 1px solid #f3f3f3;
  background-color: #fff;
  border-radius: 20px 20px 0 0;
  font-size: 24px;
  color: #666;
  font-weight: 400;
  cursor: pointer;
  user-select: none;
}
.course .course-tab-radio:checked+.course-tab {
  color: #32b16b;
  background-color: #fbfbfb;
  border: 1px solid #fbfbfb;
}
.course #course-tab-01:checked~#course-page-01,
.course #course-tab-02:checked~#course-page-02,
.course #course-tab-03:checked~#course-page-03,
.course #course-tab-04:checked~#course-page-04 {
  display: block;
}
.course .course-page {
  display: none;
  width: 100%;
  background-color: #fbfbfb;
  padding: 75px 150px;
  box-sizing: border-box;
}
.course .course-page .course-page-text {
  color: #303030;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 25px;
}
.course .course-page .course-page-content {
  width: 794px;
  margin: 0 auto;
}
.course .course-page .course-page-content .course-page-table-head {
  font-size: 20px;
  display: flex;
  align-items: flex-end;
}
.course .course-page .course-page-content .course-page-table-head .course-page-table-head-item {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 6px solid #fbfbfb;
  border-left: none;
  border-bottom: none;
  box-sizing: border-box;
  padding: 10px;
  margin-right: 1px;
}
.course .course-page .course-page-content .course-page-table-head .course-page-table-head-item:first-child {
  border-left: 6px solid #fbfbfb;
}
.course .course-page .course-page-content .course-page-table-head .course-page-table-head-item span {
  display: block;
  font-size: 60px;
}
.course .course-page .course-page-content .course-page-table-head .course-page-table-head-item.h110 {
  height: 110px;
}
.course .course-page .course-page-content .course-page-table-head .course-page-table-head-item.h135 {
  height: 135px;
}
.course .course-page .course-page-content .course-page-table-head .course-page-table-head-item.h160 {
  height: 160px;
}
.course .course-page .course-page-content .course-page-table-head .course-page-table-head-item.h185 {
  height: 185px;
}
.course .course-page .course-page-content .course-page-table-head .course-page-table-head-item.bgcy {
  background-color: #fdde00;
}
.course .course-page .course-page-content .course-page-table-head .course-page-table-head-item.bgcw {
  background-color: #fff;
}
.course .course-page .course-page-content .course-page-table {
  color: #333;
  border-collapse: collapse;
}
.course .course-page .course-page-content .course-page-table .course-page-table-td {
  border: 6px solid #fbfbfb;
  font-size: 16px;
  width: 200px;
  height: 140px;
  text-align: center;
  box-sizing: border-box;
  padding: 10px;
}
.course .course-page .course-page-content .course-page-table .course-page-table-td.fs18 {
  font-size: 18px;
}
.course .course-page .course-page-content .course-page-table .course-page-table-td.bgcw {
  background-color: #fff;
}
.course .course-page .course-page-content .course-page-table .course-page-table-td.bgcy {
  background-color: #fdde00;
}
.course .course-page .course-page-content .course-page-table .course-page-table-td.w100 {
  width: 100px;
}
.course .course-page .course-page-content .course-page-table .course-page-table-td.w600 {
  width: 600px;
}
/* process */
.process {
  background-color: #fbfbfb;
  min-width: 1024px;
  position: relative;
}
.process #nav-process {
  position: absolute;
  top: -80px;
}
.process .process-content {
  display: flex;
  justify-content: center;
}
.process .process-box {
  width: 1310px;
  height: 757px;
  margin: 0 auto;
  position: relative;
}
.process .process-box .process-cloud-01 {
  width: 906px;
  height: 649px;
  position: absolute;
  left: 0;
  top: 0;
}
.process .process-box .process-cloud-02 {
  width: 575px;
  height: 449px;
  position: absolute;
  right: 0;
  bottom: 0;
}
.process .process-box .process-video-box {
  width: 878px;
  height: 595px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.process .process-box .process-video-box .process-video {
  width: 77%;
  height: 71%;
  background-color: #000;
  position: absolute;
  top: 14%;
  left: 11.5%;
  right: 0;
  bottom: 0;
}
.process .process-box .process-video-box .process-notebook {
  width: 100%;
}
.process .process-prepare {
  padding: 45px 0 85px 0;
}
.process .process-prepare .process-prepare-title {
  font-size: 24px;
  color: #666;
  text-align: center;
  margin-bottom: 34px;
}
.process .process-prepare .process-prepare-box {
  display: flex;
}
.process .process-prepare .process-prepare-box .process-prepare-cell {
  padding: 20px 20px 0 20px;
  margin: 0 0 20px 0;
  text-align: center;
  min-height: 130px;
  box-sizing: border-box;
}
.process .process-prepare .process-prepare-box .process-prepare-cell .process-svg {
  width: 180px;
  height: 55px;
  fill: currentColor;
  overflow: hidden;
}
.process .process-prepare .process-prepare-box .process-prepare-cell:nth-of-type(1),
.process .process-prepare .process-prepare-box .process-prepare-cell:nth-of-type(2) {
  flex: 3;
}
.process .process-prepare .process-prepare-box .process-prepare-cell:nth-of-type(3),
.process .process-prepare .process-prepare-box .process-prepare-cell:nth-of-type(4),
.process .process-prepare .process-prepare-box .process-prepare-cell:nth-of-type(5) {
  flex: 1.8;
}
.process .process-prepare .process-prepare-box .process-prepare-cell img {
  display: block;
  margin: 0 auto;
}
.process .process-prepare .process-prepare-box .process-prepare-cell span {
  padding: 30px 0 5px 0;
  display: block;
  font-size: 14px;
  color: #666;
}
.process .process-prepare .process-prepare-box .process-prepare-cell a {
  font-size: 12px;
  color: #32b16b;
}
.process .process-prepare .process-prepare-line {
  display: block;
  width: 1px;
  height: 78px;
  background-color: #e6e6e6;
}
.process .process-prepare .process-prepare-or {
  display: block;
  line-height: 90px;
}
/* agency */
.agency {
  display: flex;
  flex-wrap: wrap;
  padding: 80px 0 72px 0;
  justify-content: center;
  background-color: #fff;
  position: relative;
}
.agency #nav-agency {
  position: absolute;
  top: -80px;
}
.agency .agency-cell {
  width: 20%;
}
.agency .agency-cell img {
  width: 100%;
}
/* footer */
.footer {
  background-color: #303a4a;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #fff;
  font-weight: 400;
}
.footer a {
  color: #fff;
}
.service {
  width: 80px;
  height: 80px;
  box-shadow: 0px 2px 20px 0px rgba(230,230,230,0.5);
  background-color: #32b16b;
  position: fixed;
  right: 50px;
  bottom: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  cursor: pointer;
  border-radius: 5px;
}
.service .service-icon {
  color: #fff;
  font-size: 40px;
}
.service .service-text {
  color: #fff;
  font-size: 12px;
}
#show-modal {
  cursor: pointer;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.3);
  z-index: 10000;
  display: none;
}
.modal .modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
}
.modal .modal-header {
  text-align: center;
  height: 80px;
  line-height: 80px;
  font-size: 32px;
  position: relative;
}
.modal .modal-close {
  position: absolute;
  top: 20px;
  right: 20px;
  box-sizing: border-box;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 40px;
  cursor: pointer;
}
.modal .modal-close::after,
.modal .modal-close::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 16px;
  height: 2px;
  background: currentColor;
  transform: rotate(45deg);
  border-radius: 5px;
  top: 8px;
  left: 1px;
}
.modal .modal-close::after {
  transform: rotate(-45deg);
}
.modal .modal-content {
  width: 800px;
  height: 800px;
  overflow-y: auto;
  padding: 20px;
}
